@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-slate-700 tracking-wide;
  }
  a {
    @apply text-primary;
  }
  body,
  button {
    @apply font-open-sans;
  }
  body {
    @apply bg-main-bg;
  }
}

.svg-container {
  @apply aspect-square;
}

@layer utilities {
  .unset-all {
    all: unset;
  }
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
