img {
  vertical-align: middle;
  border-style: none;
}

h3,
h3 {
  /*font-size: 1.75rem;*/
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  text-decoration: underline;
}

.search-table-wrap thead > tr > th:first-child {
  width: 15%;
}

.search-table-wrap thead > tr > th:nth-child(2) {
  width: 20%;
}

.search-table-wrap thead > tr > th:last-child {
  width: 15%;
}
